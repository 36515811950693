import React, { useEffect } from 'react';
import { Loader } from '../components/Loader';
import { captureException } from '@sentry/nextjs';

interface LoadingFullScreenProps {
  debugLabel: string;
}

export const LoadingFullScreen: React.VFC<LoadingFullScreenProps> = ({ debugLabel }) => {
  const label = `[LOADER FULL SCREEN]: ${debugLabel}`;
  console.log(label);
  useEffect(() => {
    const timeOut = setTimeout(() => {
      console.error(label);
      captureException(new Error(label));
    }, 5000);
    return () => clearTimeout(timeOut);
  }, []);
  return (
    <div className="fixed top-0 bottom-0 right-0 left-0 z-50 bg-white flex items-center justify-center h-screen w-screen">
      <Loader variant="full" />
    </div>
  );
};
