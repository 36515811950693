import { useState } from 'react';
export var useToggle = function useToggle() {
  var initial = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

  var _useState = useState(initial),
      isOpen = _useState[0],
      setIsOpen = _useState[1];

  function toggle() {
    setIsOpen(!isOpen);
  }

  return [isOpen, toggle];
};