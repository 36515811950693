import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import clsx from 'clsx';
import { More } from '../../assets/icons';

export interface DropdownProps {
  children: React.ReactElement;
  content: JSX.Element;
  isOpen: boolean;
  setIsOpen: (bool: boolean) => any;
  className?: string;
}

export const Dropdown: React.FC<DropdownProps> = ({ children, className, content, isOpen, setIsOpen, ...rest }) => {
  let [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>();
  let [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
  });

  return (
    <Popover className={className ?? ''} {...rest}>
      <Popover.Button ref={setReferenceElement}>{children}</Popover.Button>
      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className="rounded-lg bg-white shadow-select p-2 z-10 w-max flex flex-col"
      >
        {content}
      </Popover.Panel>
    </Popover>
  );
};

export interface MenuItemProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  icon: JSX.Element;
  danger?: boolean;
}

export const MenuItem: React.FC<MenuItemProps> = ({ children, icon, danger, ...rest }) => {
  const menuClassNames = clsx('p-2 hover:bg-gray-100 rounded-lg flex items-center cursor-pointer', {
    'text-black': !danger,
    'text-red-500 hover:bg-red-100': danger,
  });

  const iconClassNames = clsx('mr-2 text-gray-500', {
    'text-red-500': danger,
  });
  return (
    <div className={menuClassNames} {...rest}>
      {icon && <span className={iconClassNames}>{icon}</span>}
      <span>{children}</span>
    </div>
  );
};

type IconSize = 's' | 'm';

export const DropdownTrigger = ({ size = 'm' }: { size?: IconSize }) => {
  const sizes = {
    s: 'w-8 h-8',
    m: 'w-10 h-10',
  };
  const iconSizes = {
    s: 'h-4 w-4',
    m: 'h-5 w-5',
  };
  const classNames = clsx(
    sizes[size],
    'bg-gray-100 hover:bg-gray-200 focus:bg-gray-200 text-black rounded-full inline-flex align-middle justify-center items-center',
  );
  return (
    <div className={classNames}>
      <More className={iconSizes[size]} />
    </div>
  );
};
